import { Container, NotFound, PageContainer } from '@instabox/consumer-web-ui'
import { GetStaticProps, NextPage } from 'next'
import {
  PageProps,
  localeToMarketCode,
  fetchMarketById,
  fetchPageLinks,
} from '@instabox/consumer-web-shared'

const NotFoundPage: NextPage<PageProps> = () => (
  <PageContainer>
    <Container>
      <NotFound />
    </Container>
  </PageContainer>
)

export const getStaticProps: GetStaticProps<PageProps> = async ({ locale }) => {
  const marketCode = localeToMarketCode(locale)

  const {
    data: { marketById },
  } = await fetchMarketById(marketCode)

  const pageLinks = await fetchPageLinks(locale)

  return {
    props: {
      market: marketById,
      pageLinks,
    },
    revalidate: 60,
  }
}

export default NotFoundPage
